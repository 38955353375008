import React, { useState } from "react";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  Logo,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from "./HeroElements";
import { animateScroll as scroll } from "react-scroll";
import { Button } from "../ButtonElement";
import video from "../../videos/video.mp4";
import logo from "../../images/logo.png";
const HeroSection = () => {

  const toggleHome = () => {
    scroll.scrollToBottom();};

  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <HeroContainer id="home">
      <HeroBg>
        <VideoBg autoPlay loop muted src={video} type="video/mp4" />
      </HeroBg>
      <HeroContent>
      <Logo src={logo} type="logo/png" />
        <HeroH1>RISE Middle East Company Ltd</HeroH1>
        <HeroP>
        To provide quality services that exceed the expectations of our esteemed customers  
        </HeroP>
        <HeroBtnWrapper>
          <Button
            smooth={true}
            duration={1000}
            spy={true}
            exact={true}
            offset={-80}
            primary={true}
            dark={true}
            class="primary"
            onClick={toggleHome} to="/"
            onMouseEnter={onHover}
            onMouseLeave={onHover}
          >
            Contact Us {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
