import img1 from "../../images/svg-1.svg";
import img2 from "../../images/svg-2.svg";
import img3 from "../../images/svg-3.svg";
import img4 from "../../images/Companies.jpg";
export const aboutDataOne = {
  id: "about",
  lightBg: false,
  lightText: true,
  topLine: "RISE Middle East Company Ltd",
  headlline: "About",
  description:
  <ul>
  <li>Established in the year 2007.</li><br/>
  <li>Experienced and growing work Force to handle large scale of Projects.</li><br/>
  <li>We deliver projects with focus on safety, quality, and time.</li><br/>
  <li>Our Ground ZERO Level involvement allows us to effectively manage the challenges involved in Projects.</li><br/>
</ul>,
  imgStart: false,
  img: img1,
  alt: "_1",
  dark: true,
  primary: true,
  darkText: false,
};

export const aboutDataTwo = {
  id: "business-activities",
  lightBg: true,
  lightText: false,
  topLine: "RISE Middle East Company Ltd",
  headlline: "Business Activities",
  description:
  <ul>
  <li>Construction, Engineering & Design, Contracting, Maintenance & Trading.</li><br/>
  <li>Safe, Timely Completion of projects with Quality Adherence,.</li><br/>
  <li>We are expanding our business in importation of Sisal Fibre From Kenya.</li><br/>
  <li>We import Electrical & HVAC Equipment's from China for Various Royal Commission Projects in Yanbu.</li><br/>
  <li>We have expanded our business into Saudi tourism and Real Estate.</li><br/>
</ul>,
  imgStart: true,
  img: img2,
  alt: "_2",
  dark: false,
  primary: false,
  darkText: true,
};


export const aboutDataThree = {
  id: "company-strategy",
  lightBg: false,
  lightText: true,
  topLine: "RISE Middle East Company Ltd",
  headlline: "Company Strategy",
  description:
  <ul>
  <li><b>Purpose: </b>To be a leader in the Competitive Market, industry by providing enhanced 
services, Complying International Standards, Client’s Requirements, up to date 
of Today’s Technologies, Professional relationship, and maintaining 
profitability.</li><br/>
  <li><b>Vision: </b>To Provide Quality services that exceed the expectations of our Esteemed 
Customers.</li><br/>
  <li><b>Mission: </b>To build long term relationships with our customers and clients and provide 
exceptional customer services by pursuing business through innovation and 
advanced technology.</li><br/>
</ul>,
  imgStart:false,
  img: img3,
  alt: "_3",
  dark: true,
  primary: true,
  darkText: false,
};

export const aboutDataFour = {
  id: "major-clients",
  lightBg: true,
  lightText: false,
  topLine: "RISE Middle East Company Ltd",
  headlline: "Major Clients",
  description:
  <ul>
  <li>Saudi Aramco</li>
  <li>Royal Commission Yanbu</li>
  <li>Cristal Titnium Di-Oxide Ltd.</li>
  <li>Bilfal Heavy Industries Ltd.</li>
  <li>Saudi Binladin Groups.</li>
  <li>El Seif Construction Co. Ltd.</li>
  <li>Ryterna LLC </li>
  <li>Nasser Al Hajri Co. Ltd.</li>
  <li>National Petroleum Co. NATPET </li>
  <li>LUBREF Petrochemicals </li>
  <li>YASREF Petrochemicals </li>
  <li>Isam Kabbani Groups</li>
</ul>,
  imgStart: true,
  img: img4,
  alt: "_4",
  dark: false,
  primary: false,
  darkText: true,
};


