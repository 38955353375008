import React from "react";
import {
    FaLocationArrow,
    FaPhoneAlt,
    FaFax,
    FaEnvelope
  } from "react-icons/fa";
import {
  FooterContainer,
  FooterWrapper,
  FooterLinksContainer,
  FooterLinkWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrapper,
  SocialLogo,
  WebsiteRights
} from "./FooterElements";

import { animateScroll as scroll } from "react-scroll";

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <FooterContainer>
        <FooterWrapper>
           <FooterLinksContainer>
            <FooterLinkWrapper>

              <FooterLinkItems>
                <FooterLinkTitle>Contact Us</FooterLinkTitle>
                <FooterLink to="/">
                <FaLocationArrow />  P.O Box - 4124, Usman Bin Affan Street, AL - Hadaiq District, Yanbu AL - Bahr, Kingdom of Saudi Arabia.
                  </FooterLink>
                  <br/>
                <FooterLink to="/">
                  <FaPhoneAlt/>  966 014 322 9213
                </FooterLink>
                <br/>
                <FooterLink to="/">
                  <FaFax/>  966 014 3229 213
                  </FooterLink>
                  <br/>
                <FooterLink to="/">
                <FaEnvelope/>  sales@riseksa.co</FooterLink>
              </FooterLinkItems>
            </FooterLinkWrapper>

          </FooterLinksContainer>
        </FooterWrapper>  
        <SocialMedia>
          <SocialMediaWrapper>
            <SocialLogo onClick={toggleHome} to="/">
            RISE Middle East Company Ltd
            </SocialLogo>
            <WebsiteRights>
            RISE Middle East &copy; {new Date().getFullYear()} All rights reserved.
            </WebsiteRights>
          </SocialMediaWrapper>
        </SocialMedia>
      </FooterContainer>
    </>
  );
};

export default Footer;
